import Fraction from "@/fraction";

export function useBackend() {
    // auto determine backend url
    let rootUrl = 'https://lfg.everhem.com/api/';

    if (window.location.hostname.includes('localhost')) {
        // rootUrl = 'https://everhem-staging-backend.azurewebsites.net/api/';
        rootUrl = 'http://localhost:8082/api/';
    } else if (window.location.hostname.includes('everhem-2-0-stage.webflow.io')) {
        rootUrl = 'https://everhem-staging-backend.azurewebsites.net/api/';
    }
    
    // private helper method that makes a call to the backend, parses the JSON response (if it is json) and returns it    
    async function callApi(endpoint, body, method) {
        // set the method if not specified
        if (!method) {
            if (body) {
                method = 'POST';
            }
            else {
                method = 'GET';
            }
        }

        // set content type from the body
        let contentType = 'application/x-www-form-urlencoded';
        if (body && (body.includes('{') || body.includes('['))) {
            contentType = 'application/json';
        }

        // do call
        try {
            const response = await fetch(rootUrl + endpoint, {
                method: method,
                credentials: 'include',
                headers: {
                    'Content-Type': contentType
                },
                body: body
            });

            if (response.body) {
                return await response.json();
            }
            else if (response.ok) {
                return true;
            }            
        }
        catch (error) {
            console.error(error);
        }

        return false;
    }

    function buildProductRequestData(data) {
        // build data to send to endpoint
        const requestData = [];

        if (data.type === 'swatch') {
            requestData.push(`type=${data.type}`);
            requestData.push(`swatch=${data.swatch}`);
            return requestData;
        }

        // determine which ruler data to add
        // some data has packages that don't require all of the measurements (hardware only for example)
        let addRulersFromConfigureCategory = true;
        let addRulersFromMeasureCategory = true;
        if (data.currentPackage) {
            if (data.currentPackage.value.skipMeasurer) {
                // add rulers for steps in the configure category only
                addRulersFromMeasureCategory = false;
            }
            else {
                // add rulers for steps in the measure category only
                addRulersFromConfigureCategory = false;
            }
        }

        // add itemId if user is editing an item that is already in the cart
        if (data.itemId) {
            requestData.push(`itemId=${encodeURIComponent(data.itemId.value)}`);    
        }
        
        requestData.push(`label=${encodeURIComponent(data.windowName.value)}`);
        requestData.push(`type=${data.type}`);
        
        Object.entries(data.steps).forEach((kv) => {
            const step = kv[1];
            const selected = step.selected.value;

            if (step.disabled?.value != true) {
                if (step.doNotSendSelectedToBackend != true) {
                    requestData.push(`${step.id}=${selected.id}`);
                }    

                // add measurements
                if (step.rulers) {
                    if ((addRulersFromConfigureCategory && step.category == 'configure') || (addRulersFromMeasureCategory && step.category == 'measure')) {
                        step.rulers.forEach((ruler) => {
                            const { whole, decimal } = Fraction.split(ruler.current.value);
    
                            requestData.push(`${ruler.id}=${whole}`);
                            requestData.push(`${ruler.id}Fraction=${decimal}`);
                        });
                    }
                }

                // get mounting height for drapery, if present            
                if (selected.id === 'mountHeight') {
                    const dropDownValue = selected.rulerAdjustment.current.value;
                    requestData.push(`customDropdown=${dropDownValue}`);
                } else if (step.id === 'windowHeight' && selected.rulerAdjustment != undefined) {
                    const shadeMountingHeight = Fraction.split(selected.rulerAdjustment.current.value);
                    requestData.push(`mountingHeight=${shadeMountingHeight.whole}`);
                    requestData.push(`mountingHeightFraction=${shadeMountingHeight.decimal}`);
                }
            }
        });  

        return requestData;
    }

    // query backend for stock levels
    async function checkStock(skusToCheck) {        
        let skusInStock = [];

        // check each sku against the backend
        for (let i = 0; i < skusToCheck.length; i++) {
            let sku = skusToCheck[i];

            // query the backend api
            let result = await callApi('stock', `sku=${sku}`);
            if (result && result.isOutOfStock == false) {
                skusInStock.push(sku);
            }
        }

        // return which skus are in stock
        return skusInStock;
    }

    // send email to backend (presumably to create a new cart)
    async function saveCartEmail(email) {
        return await callApi('save-cart-email', `{"email":"${email.value}"}`);
    }

    // calculate price of the product
    async function calculatePrice(data) {        
        // build data to send to endpoint
        const formData = buildProductRequestData(data); 

        // send date to backend (as formdata)
        return await callApi('calculate-price', formData.join('&'));
    }

    // get the items for a saved cart
    async function getCart() {        
        return await callApi('cart');
    }

    // add a dynamic item to the cart
    async function addCartItem(data) {
        // build data to send to endpoint
        const formData = buildProductRequestData(data); 

        // send date to backend (as formdata)
        return await callApi('add-to-cart', formData.join('&'));
    }

    // add an item to the cart
    async function addCartSwatchItem(data) {
        // build data to send to endpoint
        const formData = buildProductRequestData(data); 

        // send date to backend (as formdata)
        return await callApi('add-to-cart', formData.join('&'));
    }

    // update an item in the cart
    async function updateCartItem(itemId, itemLabel, newQuantity) {
        return await callApi('update-cart', `itemId=${encodeURIComponent(itemId)}&label=${encodeURIComponent(itemLabel)}&quantity=${newQuantity}`);
    }

    // remove an item from the cart
    async function removeCartItem(itemId) {
        return await callApi('remove-cart-item', `itemId=${encodeURIComponent(itemId)}`);
    }

    // log a user in
    async function login(username, password) {
        return await callApi('customer-login', `email=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`);
    }

    // log a user out
    async function logout() {
        return await callApi('customer-logout', null, 'POST');
    }

    // gets the info for the currently logged in user
    async function getAccountInfo() {
        return await callApi('account');
    }

    // creates an account for the user
    async function createAccount(firstName, lastName, email, password, confirmPassword, whereHearAboutUs, newsletterSignup, signupToken) {
        const body = `firstName=${firstName}&lastName=${lastName}&email=${email}&password=${password}&confirmPassword=${confirmPassword}&source=${whereHearAboutUs}&newsletterOptIn=${newsletterSignup}&token=${signupToken}`;
        return await callApi('customer-signup', body);
    }

    // updates the info for the currently logged in user
    async function updateAccountInfo(firstName, lastName, email) {
        const body = `firstName=${firstName}&lastName=${lastName}&email=${email}`;
        return await callApi('update-account', body);
    }

    // sends the user a password reset email if their email exists in the system
    async function sendPasswordReset(email) {
        const body = `email=${email}`;
        return await callApi('forgot-password', body);
    }

    async function resetPassword(password, confirmPassword, resetToken) {
        const body = `password=${password}&confirmPassword=${confirmPassword}&resetToken=${resetToken}`;
        return await callApi('reset-password', body);
    }

    // gets the list of orders for the currently logged in user
    async function getOrderList() {
        return await callApi('orders');
    }

    // gets an order by its id
    async function getOrder(orderId) {
        return await callApi(`order-details?id=${encodeURIComponent(orderId)}`);
    }

    // gets the list of quotes for the currently logged in user
    async function getQuoteList() {
        return await callApi('quotes');
    }

    // gets a quote by its id
    async function getQuote(quoteId) {
        return await callApi(`quote?id=${encodeURIComponent(quoteId)}`);
    }

    // add quote to cart
    async function addQuoteToCart(quoteId) {
        return await callApi('add-quote-to-cart', `id=${encodeURIComponent(quoteId)}`);
    }

    // requests a checkout url from the api
    async function getCheckoutUrl() {
        return await callApi('checkout', '', 'POST');
    }

    return { 
        checkStock,
        saveCartEmail,
        calculatePrice,
        getCart,
        addCartItem,
        addCartSwatchItem,
        updateCartItem,
        removeCartItem,
        login,
        logout,
        getAccountInfo,
        createAccount,
        updateAccountInfo,
        sendPasswordReset,
        resetPassword,
        getOrderList,
        getOrder,
        getQuoteList,
        getQuote,
        addQuoteToCart,
        getCheckoutUrl,
    };
}
export class Helpers {
    static getDefaultOption(step) {
        let defaultOption = null;

        // try to select the first option flagged as default that isn't disabled
        step.options.every(option => {
            if (option.default && !(option.disabled && option.disabled.value)) {
                defaultOption = option;
                return false;
            }
            else {
                return true;
            }
        });

        // if still no selection, then fallback to the first option that isn't disabled
        if (defaultOption == null) {
            step.options.every(option => {
                if (!(option.disabled && option.disabled.value)) {
                    defaultOption = option;
                    return false;
                }
                else {
                    return true;
                }
            });            
        }

        return defaultOption;
    }

    static getStandardDeviation(array) {
        if (!array || array.length === 0) {
            return 0;
        }
        else {
            const length = array.length;
            const mean = array.reduce((a, b) => a + b) / length;

            return Math.sqrt(array.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / length);
        }
    }

    static jsonToQuery(queryOut, jsonObject, parentName) {
        Object.keys(jsonObject).forEach(function(propName) {
            if (typeof jsonObject[propName] === 'object') {
                Helpers.jsonToQuery(queryOut, jsonObject[propName], propName);
            }
            else {
                let qs = propName;
                if (parentName) {
                    qs = `${parentName}.${qs}`;
                }
                queryOut[qs] = jsonObject[propName];
            }
        });
    }

    static getClosestNumber(currentValue, availableValues) {
        let closest = availableValues[0];  // Start with the first available
        let smallestDifference = Math.abs(currentValue - closest);

        for (let i = 1; i < availableValues.length; i++) {
            const currentDepth = availableValues[i];
            const currentDifference = Math.abs(currentValue - currentDepth);

            if (currentDifference < smallestDifference) {
                smallestDifference = currentDifference;
                closest = currentDepth;
            }
        }

        return closest;
    }

    static calcRulerMinOrMax(data, lookup, widthOrHeight, minOrMax) {        
        const defaultValue = lookup['default']['default'][widthOrHeight][0][minOrMax];

        const mountConfig = lookup[data.steps.mountPosition.selected.value.id];
        if (!mountConfig) {
            console.warn(`No mount configuration found for type: ${data.steps.mountPosition.selected.value.id}`);
            return defaultValue;
        }

        const controlConfig = mountConfig[data.steps.customControl.selected.value.id][widthOrHeight];
        if (!controlConfig) {
            console.warn(`No control configuration found for: ${data.steps.customControl.selected.value.id}`);
            return defaultValue;
        }

        // Extract available values and find the closest
        const availableValues = Object.keys(controlConfig).map(Number);
        const closestValue = Helpers.getClosestNumber(data.steps.mountPosition.rulers[0].current.value, availableValues);

        const settings = controlConfig[closestValue];
        if (!settings) {
            console.warn(`No settings found for depth: ${closestValue}`);
            return defaultValue;
        }

        // Special SKU logic
        const specialSKUs = ['HTHCM', 'HTHSG', 'HTHNT', 'OAKMS'];
        const selectedSKU = data.steps.fabric.selected.value.id;
        const selectedMountPosition = data.steps.mountPosition.selected.value;
        // const selectedCustomControl = data.steps.customControl.selected.value;
        const selectedMountPositionDepth = data.steps.mountPosition.rulers[0].current.value;

        console.debug('selectedMountPosition: ', selectedMountPosition);
        console.debug('selectedMountPositionDepth: ', selectedMountPositionDepth);
        
        
        if (widthOrHeight === 'width') {
            if (specialSKUs.includes(selectedSKU)) {
                let customLogicValue = settings[minOrMax];
                if (minOrMax === 'max') {
                    if (customLogicValue > 78) {
                        customLogicValue = 96;
                    }
                }
    
                return customLogicValue;
            }

        }

        return settings[minOrMax];
    }
}
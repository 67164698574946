import { Helpers } from '@/helpers';

export function useTracking() {
  // private helpers
  function sendEvent(name, payload) {
    // eslint-disable-next-line no-undef
    analytics.track(name, payload);
  }

  function sendUser(id, email, firstName, lastName) {
    // eslint-disable-next-line no-undef
    analytics.identify(id, {
      email: email,
      firstName: firstName,
      lastName: lastName
    });
  }

  function buildStepPayload(productType, windowName, steps) {
    var payload = {
      product: productType,
      steps: []
    };

    if (windowName) {
      payload.windowName = windowName;
    }

    steps.forEach((step) => {
      var data = {
        id: step.id,        
      };

      // steps with only one option are likely measurement steps and the option selection isn't relevant
      if (step.options.length > 1) {
        data.default = Helpers.getDefaultOption(step).id;
        data.selected = step.selected.value.id;
      }
            
      if (step.rulersMax) {
        data.measurement = step.rulersMax.value;
      }

      payload.steps.push(data);
    });

    return payload;
  }

  // public API
  function goingToNextStep(productType, windowName, displayedSteps) {
    var payload = buildStepPayload(productType, windowName, displayedSteps);
    sendEvent('Going to Next Step', payload);
  }

  function goingToMeasurer(productType, windowName, configurationSteps) {
    var payload = buildStepPayload(productType, windowName, configurationSteps);    
    sendEvent('Configured Product', payload);
  }

  function goingToConfirmation(productType, windowName, allSteps) {
    var payload = buildStepPayload(productType, windowName, allSteps);    
    sendEvent('Measured Product', payload);
  }

  function addToCart(cartId, productType, windowName, step) {
    const payload = buildStepPayload(productType, windowName, step);
    // Add the cartId property to the payload specifically for the add to cart event
    payload.cartId = cartId;
    sendEvent('Add to Cart', payload);
  }

  function startedCheckout(cartId, customer, items, tradeMember, hasSwatches) {
    const payload = {cartId, customer, items, tradeMember, hasSwatches}
    sendEvent('Started Checkout', payload);
  }

  function currentUser(id, email, firstName, lastName) {
    sendUser(id, email, firstName, lastName);
  }

  return {
    goingToNextStep,
    goingToMeasurer,
    goingToConfirmation,
    addToCart,
    startedCheckout,
    currentUser
  };
}